import { FC, useContext, useMemo } from "react";
import Head from "next/head";
import { LocaleContext, useRuntimeConfig, useTranslation } from "@finbackoffice/site-core";
import { TranslationScopes } from "@finbackoffice/enums";
import { formatAppNameText } from "@finbackoffice/fe-core";
import { useHost } from "hooks";

type ISeoPropsReplace = {
    title: { [key: string]: string | number };
    description?: { [key: string]: string | number };
    keywords?: { [key: string]: string | number };
};

type ISeoProps = {
    page?: TranslationScopes;
    replace?: ISeoPropsReplace;
    twitterImg?: string;
    ogImg?: string;
};

const Seo: FC<ISeoProps> = ({ page, replace, twitterImg, ogImg }) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { t } = useTranslation(page ?? TranslationScopes.Common);
    const { fullPath } = useHost();
    const { locale } = useContext(LocaleContext);
    const titleTranslation = t("title", {
        ...replace?.title,
        app_name: COMMON_SITE_CONFIGS.appName,
    });
    const title = useMemo(
        () =>
            titleTranslation !== "title"
                ? titleTranslation
                : t("title", {
                      ns: TranslationScopes.Common,
                      ...replace?.title,
                      app_name: COMMON_SITE_CONFIGS.appName,
                  }),
        [COMMON_SITE_CONFIGS.appName, replace?.title, t, titleTranslation],
    );

    const ogImageContentMemo = useMemo(() => {
        const dir = `${ASSETS_URL}/${formatAppNameText(COMMON_SITE_CONFIGS.appName)}/common`;
        let imgName = COMMON_SITE_CONFIGS.seo.singleSocialImage
            ? "social-image"
            : ogImg || "social-image";

        if (COMMON_SITE_CONFIGS.seo.langSocialImage) {
            imgName = `${imgName}-${locale}`;
        }

        return `${dir}/${imgName}.jpg`;
    }, [
        ASSETS_URL,
        COMMON_SITE_CONFIGS.appName,
        COMMON_SITE_CONFIGS.seo.langSocialImage,
        COMMON_SITE_CONFIGS.seo.singleSocialImage,
        locale,
        ogImg,
    ]);

    const twitterImageContentMemo = useMemo(() => {
        const dir = `${ASSETS_URL}/${formatAppNameText(COMMON_SITE_CONFIGS.appName)}/common`;
        let imgName = COMMON_SITE_CONFIGS.seo.singleSocialImage
            ? "twitter-social-image"
            : twitterImg || "twitter-social-image";

        if (COMMON_SITE_CONFIGS.seo.langSocialImage) {
            imgName = `${imgName}-${locale}`;
        }

        return `${dir}/${imgName}.jpg`;
    }, [
        ASSETS_URL,
        COMMON_SITE_CONFIGS.appName,
        COMMON_SITE_CONFIGS.seo.langSocialImage,
        COMMON_SITE_CONFIGS.seo.singleSocialImage,
        locale,
        twitterImg,
    ]);

    return (
        <Head>
            <title key="title">{title}</title>
            <meta
                name="description"
                key="desc"
                content={t("description", {
                    ...replace?.description,
                    app_name: COMMON_SITE_CONFIGS.appName,
                })}
            />
            <meta
                name="keywords"
                content={t("keywords", {
                    ...replace?.keywords,
                    app_name: COMMON_SITE_CONFIGS.appName,
                })}
            />

            <meta property="og:site_name" content={COMMON_SITE_CONFIGS.appName} />
            <meta property="og:title" content={title} />
            <meta
                property="og:description"
                content={t("description", {
                    ...replace?.description,
                    app_name: COMMON_SITE_CONFIGS.appName,
                })}
            />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={fullPath} />
            <meta property="og:image" content={ogImageContentMemo} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:image" content={twitterImageContentMemo} />
            <meta
                property="twitter:site"
                content={`@${COMMON_SITE_CONFIGS.socialPlatforms.twitter}`}
            />

            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
            <meta
                name="viewport"
                content="width=device-width,user-scalable=yes,initial-scale=1.0"
            />
            <meta
                name="naver-site-verification"
                content="741810affc459d444728b7d13b2cce40ed613bfc"
            />
        </Head>
    );
};

export default Seo;
