import { ForwardedRef, forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import { ITournamentFeedResponse } from "@finbackoffice/websocket-client";
import styles from "./prematch-sidebar-tournament.module.sass";
import PrematchSidebarTournament from "./PrematchSidebarTournament";

interface IProps {
    tournaments: ITournamentFeedResponse[];
    sportId: number;
    sportSlug: string;
    categoryId: number;
}

const PrematchSidebarTournamentsContainer: ForwardRefExoticComponent<
    IProps & RefAttributes<HTMLDivElement>
> = forwardRef(
    (
        { tournaments, sportId, categoryId, sportSlug }: IProps,
        ref: ForwardedRef<HTMLDivElement>,
    ) => (
        <div className={styles.tournamentItemsList}>
            <div ref={ref} className={styles.tournamentItemsListContainer}>
                {tournaments &&
                    tournaments.map((tournament: ITournamentFeedResponse) => (
                        <PrematchSidebarTournament
                            key={tournament.id}
                            tournament={tournament}
                            sportId={sportId}
                            sportSlug={sportSlug}
                            categoryId={categoryId}
                        />
                    ))}
            </div>
        </div>
    ),
);

export default PrematchSidebarTournamentsContainer;
