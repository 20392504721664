import React, { FC } from "react";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import { ISidebarViewType } from "../SidebarContainer";
import LiveSimpleSidebar from "./live/LiveSimpleSideBar";
import PrematchSimpleSidebar from "./prematch/PrematchSimpleSideBar";
import styles from "./simple-sidebar.module.sass";

type IProps = {
    viewType: ISidebarViewType;
};

const SimpleSidebar: FC<IProps> = ({ viewType }) => {
    return (
        <section className={styles.simpleSideBar} data-testid="sportsbook-simple-sidebar">
            {viewType === "sport" && (
                <ErrorBoundary name={PrematchSimpleSidebar.name}>
                    <PrematchSimpleSidebar />
                </ErrorBoundary>
            )}
            {viewType === "live" && (
                <ErrorBoundary name={LiveSimpleSidebar.name}>
                    <LiveSimpleSidebar />
                </ErrorBoundary>
            )}
        </section>
    );
};

export default SimpleSidebar;
