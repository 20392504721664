import { FC, useEffect, useRef, useMemo, PropsWithChildren, useContext } from "react";
import { useSelectorPositionCounter } from "hooks";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import { InitialDataContext } from "contexts";
import styles from "./simple-sidebar-popover.module.sass";

type Props = {
    arrowPosition: number;
    arrowShift?: number;
    onClose: () => void;
};

const SimpleSidebarPopover: FC<PropsWithChildren<Props>> = ({
    arrowPosition,
    arrowShift,
    onClose,
    children,
}) => {
    const { siteHeaderHeight } = useContext(InitialDataContext);
    const selectorRef = useRef<HTMLDivElement>(null);
    const selectorTop = useMemo(
        () => `${arrowPosition - (arrowShift || siteHeaderHeight)}px`,
        [arrowPosition, arrowShift, siteHeaderHeight],
    );
    useSelectorPositionCounter(selectorRef, arrowPosition);

    useEffect(() => {
        const onWindowClick = () => typeof onClose === "function" && onClose();

        window.addEventListener("click", onWindowClick);

        return () => {
            window.removeEventListener("click", onWindowClick);
        };
    }, [onClose]);

    return (
        <div className={styles.selector} style={{ top: selectorTop }}>
            <div style={{ top: `${arrowPosition}px` }} ref={selectorRef}>
                <ScrollComponent contentClass={styles.selectorContent}>{children}</ScrollComponent>
            </div>
        </div>
    );
};

export default SimpleSidebarPopover;
