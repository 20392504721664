import { FC, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { OddFormatter } from "@finbackoffice/fe-core";
import { BetSlipContext, ConfigContext, usePrevious } from "@finbackoffice/site-core";
import { MarketStatus, MarketOutcomeStatus } from "@finbackoffice/enums";
import styles from "./outcome.module.sass";

interface IOutcomeProps {
    outcome?: IMarketOutcomeFeedResponse | null;
    onOutcomeClick?: (outcome: IMarketOutcomeFeedResponse) => void;
    marketStatus?: string;
}

const Outcome: FC<IOutcomeProps> = ({ outcome, onOutcomeClick, marketStatus }) => {
    const { isOutcomeSelected } = useContext(BetSlipContext);
    const { siteCurrentOddFormat } = useContext(ConfigContext);
    const [arrowType, setArrowType] = useState("");

    const prevOdds = usePrevious(outcome?.odds) ?? "";
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isOutcomeDisabled =
        marketStatus === MarketStatus.OnHold ||
        (outcome && outcome.status === MarketOutcomeStatus.NotActive) ||
        !outcome;
    const selected = useMemo(
        () => (outcome ? isOutcomeSelected(outcome.id) : false),
        [isOutcomeSelected, outcome],
    );

    const onClickHandler = (event: SyntheticEvent) => {
        if (!isOutcomeDisabled) {
            event.stopPropagation();
            event.preventDefault();

            onOutcomeClick?.(outcome);
        }
    };

    useEffect(() => {
        if (!outcome) {
            return;
        }

        if (prevOdds && outcome) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (parseFloat(prevOdds) < parseFloat(outcome.odds)) {
                setArrowType("increasedIcon");
            } else if (parseFloat(prevOdds) > parseFloat(outcome.odds)) {
                setArrowType("decreasedIcon");
            }

            timeoutRef.current = setTimeout(() => {
                setArrowType("");
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outcome?.odds]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <div
            className={classnames(
                styles.outcomeItem,
                isOutcomeDisabled && styles.disabled,
                selected && styles.selected,
            )}
            data-testid={`outcome-${isOutcomeDisabled ? "disabled" : "enabled"}`}
            onClick={onClickHandler}>
            {outcome ? (
                <>
                    <span className={styles.outcomeItemName} title={outcome.name ?? undefined}>
                        {outcome.name}
                    </span>
                    <span className={styles.outcomeItemValue}>
                        <i className={styles[arrowType]} />{" "}
                        {OddFormatter.format(parseFloat(outcome.odds), siteCurrentOddFormat)}
                    </span>
                </>
            ) : (
                "- -"
            )}
        </div>
    );
};
export default Outcome;
