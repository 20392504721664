import { FC, useContext, useEffect, useLayoutEffect, useState } from "react";
import { SIRWidgetContext } from "contexts";
import { useSIRWidget } from "hooks";
import { ISIRWidget } from "./SIRWidgetCore";

export const SIRWidgetModal: FC<ISIRWidget> = ({
    matchId,
    sportId,
    regionId,
    onLoad,
    containerSelector,
    children,
}) => {
    const { checkSupportedSport } = useContext(SIRWidgetContext);
    const { initLmtPlusModalWidget, removeWidget, hideContent, isLicenced } = useSIRWidget();
    const [loading, setLoading] = useState<boolean>(true);

    function onInit() {
        setLoading?.(false);
        onLoad?.();
    }

    function removeInitializedWidgets() {
        checkSupportedSport?.(sportId) && removeWidget(`${containerSelector} .sr-widget-lmtplus`);
    }

    useEffect(() => {
        if (matchId && sportId) {
            setLoading?.(true);
            initLmtPlusModalWidget(sportId, regionId, matchId, containerSelector, onInit);
        } else {
            setLoading?.(false);
            removeInitializedWidgets();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchId, regionId, sportId]);

    useLayoutEffect(() => {
        return () => removeInitializedWidgets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (hideContent) return null;

    return children?.(isLicenced, loading);
};
