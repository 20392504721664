import { ForwardedRef, forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import { ICategoryFeedResponse } from "@finbackoffice/websocket-client";
import { useSelectedPrematchCategoryId } from "hooks";
import styles from "./prematch-sidebar-category.module.sass";
import PrematchSidebarCategory from "./PrematchSidebarCategory";

interface IProps {
    categories: ICategoryFeedResponse[];
    sportOpenedByDefault: boolean;
    sportId: number;
    sportSlug: string;
}

const PrematchSidebarCategoriesContainer: ForwardRefExoticComponent<
    IProps & RefAttributes<HTMLUListElement>
> = forwardRef(
    (
        { categories, sportOpenedByDefault, sportId, sportSlug }: IProps,
        ref: ForwardedRef<HTMLUListElement>,
    ) => {
        const categoryId = useSelectedPrematchCategoryId();

        return (
            <div className={styles.categoryItemsList}>
                <ul ref={ref}>
                    {categories &&
                        categories.map((category: ICategoryFeedResponse) => (
                            <PrematchSidebarCategory
                                key={category.id}
                                category={category}
                                sportId={sportId}
                                sportSlug={sportSlug}
                                openedByDefault={sportOpenedByDefault && categoryId === category.id}
                            />
                        ))}
                </ul>
            </div>
        );
    },
);

export default PrematchSidebarCategoriesContainer;
