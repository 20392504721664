import classnames from "classnames";
import { useRouter } from "next/router";
import { memo, FC, SyntheticEvent, useContext } from "react";
import { FeedContext } from "@finbackoffice/site-core";
import ActiveLink from "components/base/active-link/ActiveLink";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Translate from "components/base/translate/Translate";
import { RouteNames } from "utils/constants";
import { ISidebarViewType } from "../SidebarContainer";
import styles from "./sidebar-tabs.module.sass";

type Props = {
    viewType: ISidebarViewType | "";
    setViewType: (val: ISidebarViewType) => void;
};

const SidebarTabs: FC<Props> = ({ viewType, setViewType }) => {
    const router = useRouter();
    const { liveFeedCount } = useContext(FeedContext);

    const handleClick = (e: SyntheticEvent, targetType: ISidebarViewType) => {
        if (router.pathname === "/") {
            e.preventDefault();
            setViewType(targetType);
        }
    };

    return (
        <FadeInAnimation>
            <div className={classnames("main-tabs", styles.sideBarHeader)}>
                <ActiveLink
                    href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                    includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                    forceActive={viewType === "sport"}
                    activeClassName={styles.active}
                    className={styles.sportBtn}
                    onClick={(e) => handleClick(e, "sport")}>
                    <Translate tid="pageName_sport" />
                </ActiveLink>
                <ActiveLink
                    href={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                    forceActive={viewType === "live"}
                    activeClassName={styles.active}
                    className={styles.liveBtn}
                    onClick={(e) => handleClick(e, "live")}>
                    <>
                        <Translate tid="pageName_live" />
                        {` (${liveFeedCount})`}
                    </>
                </ActiveLink>
            </div>
        </FadeInAnimation>
    );
};

export default memo(SidebarTabs);
