import { forwardRef, ForwardRefExoticComponent, memo, useCallback, RefAttributes } from "react";
import { useRouter } from "next/router";
import { ICategoryFeedResponse } from "@finbackoffice/websocket-client";
import styles from "./live-sidebar-category.module.sass";
import LiveSidebarCategory from "./LiveSidebarCategory";

interface IProps {
    categories: ICategoryFeedResponse[];
    sportId: number;
}

const LiveSidebarCategoriesContainer: ForwardRefExoticComponent<
    IProps & RefAttributes<HTMLUListElement>
> = forwardRef(({ categories, sportId }, ref) => {
    const router = useRouter();
    const isCategorySelected = useCallback(
        (categoryId: string) =>
            router.query?.liveGameRoutes ? router.query?.liveGameRoutes[1] === categoryId : false,
        [router.query?.liveGameRoutes],
    );

    return (
        <div className={styles.container}>
            <ul ref={ref}>
                {categories &&
                    categories.map((category: ICategoryFeedResponse, index: number) => (
                        <LiveSidebarCategory
                            key={category.id}
                            category={category}
                            sportId={sportId}
                            openByDefault={
                                index === 0 || isCategorySelected(category.id.toString())
                            }
                        />
                    ))}
            </ul>
        </div>
    );
});

export default memo(LiveSidebarCategoriesContainer);
