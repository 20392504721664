import classnames from "classnames";
import { useCallback, FC, ReactElement, useContext } from "react";
import { PrematchContext } from "contexts";
import Translate from "components/base/translate/Translate";

type TimeFilterData = {
    label: string | ReactElement;
    value: number | null;
};

const timeFilterData: TimeFilterData[] = [
    {
        label: "All",
        value: null,
    },
    {
        label: <Translate tid="sidebar_prematch_24HoursBets" />,
        value: 24,
    },
    {
        label: "12H",
        value: 12,
    },
    {
        label: "6H",
        value: 6,
    },
    {
        label: "1H",
        value: 1,
    },
];

type Props = {
    onSelect?: () => void;
};

const PrematchTimeFilterTabs: FC<Props> = ({ onSelect }) => {
    const { setTimeFilter, timeFilter } = useContext(PrematchContext);
    const handleSelectFilter = useCallback(
        (value: number | null) => {
            if (value !== timeFilter) {
                setTimeFilter(value);
            }

            typeof onSelect === "function" && onSelect();
        },
        [timeFilter, onSelect, setTimeFilter],
    );

    return (
        <div className="main-tabs">
            {timeFilterData.map(({ label, value }) => (
                <div
                    key={value}
                    data-testid={`tab-${value || label}`}
                    className={classnames("main-tabs-tab", value === timeFilter && "active")}
                    onClick={() => handleSelectFilter(value)}>
                    {label}
                </div>
            ))}
        </div>
    );
};

export default PrematchTimeFilterTabs;
