import classnames from "classnames";
import { FC } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import Translate from "components/base/translate/Translate";
import { IBetSlipViewType } from "../BetSlip";
import styles from "./betslip-header.module.sass";

type IProps = {
    isLoggedIn: boolean;
    view: IBetSlipViewType;
    setView: (val: IBetSlipViewType) => void;
};

const BetSlipHeader: FC<IProps> = ({ view, setView, isLoggedIn }) => (
    <div className={styles.betSlipHeader}>
        <span
            className={classnames(view === "bet_slip" && styles.active)}
            onClick={() => setView("bet_slip")}>
            <Translate tid="betSlip_label" namespace={TranslationScopes.BetSlip} />
        </span>
        {isLoggedIn && (
            <span
                className={classnames(view === "history" && styles.active)}
                onClick={() => setView("history")}>
                <Translate tid="betSlip_history_label" namespace={TranslationScopes.BetSlip} />
            </span>
        )}
    </div>
);

export default BetSlipHeader;
