import { useCallback, useState, FC, SyntheticEvent, useContext } from "react";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { FavoriteEventsContext, FeedContext, useRuntimeConfig } from "@finbackoffice/site-core";
import classnames from "classnames";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import Loading from "components/base/loading/Loading";
import LiveSidebarSport from "components/pages/sport/sidebar/live/sport/LiveSidebarSport";
import Translate from "components/base/translate/Translate";
import Img from "components/base/img/Img";
import SportsList from "../SportsList";
import styles from "../simple-sidebar.module.sass";
import SimpleSidebarPopover from "../SimpleSidebarPopover/SimpleSidebarPopover";
import LiveTopLeagues from "../../live/top-leagues/LiveTopLeagues";

const LiveSimpleSidebar: FC = () => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { favLiveLeaguesGameCount } = useContext(FavoriteEventsContext);
    const { liveFeed, liveTopLeaguesGamesCount, liveIsLoading } = useContext(FeedContext);
    const [sport, setSport] = useState<ISportFeedResponse | null>(null);
    const [arrowPos, setArrowPos] = useState(0);
    const [isTopLeaguesOpen, setIsTopLeaguesOpen] = useState<boolean>(false);
    const isSportSelected = Boolean(sport);
    const isTopLeaguesShow = isTopLeaguesOpen && !isSportSelected;
    const isSportsShow = isSportSelected && !isTopLeaguesOpen;

    const handleClose = useCallback(() => {
        setSport(null);
    }, []);

    const handleSetSelectedSport = useCallback(
        (val: ISportFeedResponse) => {
            if (val.id === sport?.id) {
                handleClose();
            } else {
                setSport(val);
            }

            setIsTopLeaguesOpen(false);
        },
        [handleClose, sport],
    );

    const handleSelectTopLeague = useCallback((event: SyntheticEvent) => {
        setIsTopLeaguesOpen((state) => !state);
        setSport(null);
        setArrowPos(event.currentTarget.getBoundingClientRect().top);
    }, []);

    const topLeaguesNav = (
        <div onClick={handleSelectTopLeague}>
            <div className={classnames(styles.topLeagues, { [styles.open]: isTopLeaguesOpen })}>
                <span className={styles.count}>
                    {favLiveLeaguesGameCount + liveTopLeaguesGamesCount}
                </span>
                <Img
                    source={`${ASSETS_URL}/common/mobile/base-icons/favourites.svg`}
                    width={14}
                    height={14}
                    alt="top leagues"
                    wrapperClassName={styles.liveTopLeaguesIcon}
                />
                <Translate tid="sidebar_prematch_topLeagues" />
            </div>
        </div>
    );

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <span className={styles.header}>
                <Translate tid="pageName_live" />
            </span>
            <ScrollComponent
                containerClass={styles.rightSide}
                contentClass={styles.scrollableContent}
                showScrollBar
                onScroll={handleClose}>
                {favLiveLeaguesGameCount + liveTopLeaguesGamesCount !== 0 && topLeaguesNav}
                {!liveIsLoading ? (
                    <SportsList
                        sports={liveFeed}
                        setSelectedSport={handleSetSelectedSport}
                        selectedSportId={sport?.id || null}
                        setArrowPos={setArrowPos}
                    />
                ) : (
                    <Loading />
                )}
            </ScrollComponent>

            {(sport || isTopLeaguesOpen) && (
                <SimpleSidebarPopover arrowPosition={arrowPos} onClose={handleClose}>
                    {isTopLeaguesShow && <LiveTopLeagues onSelect={handleClose} />}
                    {sport && isSportsShow && <LiveSidebarSport sport={sport} openByDefault />}
                </SimpleSidebarPopover>
            )}
        </div>
    );
};

export default LiveSimpleSidebar;
