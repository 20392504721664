import { MouseEvent, useCallback, useContext } from "react";
import { IBetResponse } from "@finbackoffice/clientbff-client";
import { useTranslation } from "@finbackoffice/site-core";
import { TranslationScopes } from "@finbackoffice/enums";
import Button from "components/base/button/Button";
import { DraggableContext } from "contexts";
import styles from "../betslip-history-block.module.sass";
import { Svg } from "components/base/svg/Svg";

type IBetSlipHistoryStatistics = {
    bet: IBetResponse;
};

const BetSlipHistoryStatistics: React.FC<IBetSlipHistoryStatistics> = ({ bet }) => {
    const { t } = useTranslation(TranslationScopes.BetSlip);
    const { addItem, isActive } = useContext(DraggableContext);

    const onStatisticsCLick = useCallback(
        (ev: MouseEvent<Element>) => {
            const pageY = ev.pageY as number;
            const { sport_id, home_team, away_team, event_id, category_id } = bet;
            addItem({
                id: bet.event_id.toString(),
                top: pageY,
                left: document.getElementById("bet-slip-container")?.offsetLeft || 0,
                payload: { sport_id, home_team, away_team, event_id, category_id },
            });
        },
        [addItem, bet],
    );

    return (
        <Button
            type="button"
            title={t("betSlip_history_matchInfo")}
            className={styles.matchInfoBtn}
            onClick={onStatisticsCLick}
            disabled={isActive(bet.event_id.toString())}>
            <Svg
                src="/common/desktop/base-icons/statistics.svg"
                wrapper="span"
                className="svg-icon svg-statistics"
            />
        </Button>
    );
};

export default BetSlipHistoryStatistics;
