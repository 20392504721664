import { FC, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FeedTypeEnum, useGetCurrentFeedType, useLiveSports, usePrematchSports } from "hooks";
import { SportsbookContext } from "contexts";
import SideBar from "./Sidebar";
import SimpleSidebar from "./simple/SimpleSidebar";

export type ISidebarViewType = "sport" | "live";

const SideBarContainer: FC = () => {
    const router = useRouter();
    const feed = useGetCurrentFeedType();
    const { sidebarCollapsed } = useContext(SportsbookContext);
    const [viewType, setViewType] = useState<ISidebarViewType>("sport");
    useLiveSports();
    usePrematchSports();
    const isHome = router.pathname === "/";

    useEffect(() => {
        if (feed === FeedTypeEnum.prematch || (isHome && viewType)) {
            setViewType("sport");
        }

        if (feed === FeedTypeEnum.live || (isHome && viewType === "live")) {
            setViewType("live");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feed, isHome]);

    return sidebarCollapsed ? (
        <SimpleSidebar viewType={viewType} />
    ) : (
        <SideBar setViewType={setViewType} viewType={viewType} />
    );
};

export default SideBarContainer;
