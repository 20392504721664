import { FC, useContext } from "react";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { FeedContext } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { useSelectedLiveSportSlug } from "hooks";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Loading from "components/base/loading/Loading";
import LiveSidebarSport from "./sport/LiveSidebarSport";
import LiveTopLeagues from "./top-leagues/LiveTopLeagues";

const LiveSideBar: FC = () => {
    const { liveFeed, liveIsLoading } = useContext(FeedContext);
    const sportSlug = useSelectedLiveSportSlug();

    if (liveIsLoading) return <Loading />;

    return (
        <>
            <ErrorBoundary name={LiveTopLeagues.name}>
                <LiveTopLeagues />
            </ErrorBoundary>
            <FadeInAnimation>
                <div style={{ paddingBottom: 30 }}>
                    {liveFeed?.length === 0 && (
                        <div className="no-game">
                            <Translate tid="no_games" />
                        </div>
                    )}

                    {liveFeed.map((sport: ISportFeedResponse) => (
                        <LiveSidebarSport
                            key={sport.id}
                            sport={sport}
                            openByDefault={sportSlug === sport.slug}
                        />
                    ))}
                </div>
            </FadeInAnimation>
        </>
    );
};

export default LiveSideBar;
