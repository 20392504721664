import { ITournamentFeedResponse } from "@finbackoffice/websocket-client";
import { forwardRef, memo } from "react";
import styles from "./live-sidebar-tournament.module.sass";
import LiveSidebarTournament from "./LiveSidebarTournament";

type IProps = {
    tournaments: ITournamentFeedResponse[];
    sportId: number;
};

const LiveSidebarTournamentsContainer = forwardRef<HTMLDivElement, IProps>(
    ({ tournaments, sportId }, ref) => {
        return (
            <div ref={ref} className={styles.container}>
                {tournaments &&
                    tournaments.map((tournament) => (
                        <LiveSidebarTournament
                            key={tournament.id}
                            tournament={tournament}
                            sportId={sportId}
                        />
                    ))}
            </div>
        );
    },
);

export default memo(LiveSidebarTournamentsContainer);
