import { FC, memo } from "react";
import { ICategoryFeedResponse } from "@finbackoffice/websocket-client";
import { useCollapseMargin } from "@finbackoffice/site-core";
import { Svg } from "components/base/svg/Svg";
import { useSocketStorage, FeedTypeEnum } from "hooks";
import styles from "./prematch-sidebar-category.module.sass";
import PrematchSidebarTournamentsContainer from "./tournaments/PrematchSidebarTournamentsContainer";

interface IProps {
    category: ICategoryFeedResponse;
    openedByDefault: boolean;
    sportId: number;
    sportSlug: string;
}

const PrematchSidebarCategory: FC<IProps> = ({ category, openedByDefault, sportId, sportSlug }) => {
    const { getTournaments } = useSocketStorage(FeedTypeEnum.prematch);
    const { opened, animating, headerRef, containerRef } = useCollapseMargin(openedByDefault);

    return (
        <li
            className={styles.prematchRegionItem}
            data-testid="sidebar-prematch-category-item-container">
            <div
                className={`${styles.region} ${opened ? styles.opened : ""}`}
                ref={headerRef}
                data-isopened={opened}
                data-testid="sidebar-prematch-category-item">
                <Svg
                    src={`/common/regions/${category.id}.svg`}
                    wrapper="div"
                    className="svg-region-icon"
                    loader
                />
                <span>{category.name}</span>
                <span className={styles.gameCount}>{category.games_count}</span>
            </div>
            {(opened || animating) && (
                <PrematchSidebarTournamentsContainer
                    tournaments={getTournaments(category.id)}
                    ref={containerRef}
                    sportId={sportId}
                    sportSlug={sportSlug}
                    categoryId={category.id}
                />
            )}
        </li>
    );
};

export default memo(PrematchSidebarCategory);
