import { FC, memo } from "react";
import classnames from "classnames";
import { ICategoryFeedResponse } from "@finbackoffice/websocket-client";
import { useCollapseMargin } from "@finbackoffice/site-core";
import { Svg } from "components/base/svg/Svg";
import { useSocketStorage, FeedTypeEnum } from "hooks";
import styles from "./live-sidebar-category.module.sass";
import LiveSidebarTournamentsContainer from "./tournaments/LiveSidebarTournamentsContainer";

interface IProps {
    category: ICategoryFeedResponse;
    sportId: number;
    openByDefault: boolean;
}

const LiveSidebarCategory: FC<IProps> = ({ category, sportId, openByDefault }) => {
    const { getTournaments } = useSocketStorage(FeedTypeEnum.live);
    const { opened, animating, headerRef, containerRef } = useCollapseMargin(openByDefault);

    return (
        <li className={`${styles.categoryItemsContainer}`}>
            <div
                className={classnames(styles.categoryItem, opened && styles.opened)}
                ref={headerRef}>
                <Svg
                    src={`/common/regions/${category.id}.svg`}
                    wrapper="div"
                    className="svg-region-icon"
                    loader
                />
                <span>{category.name}</span>
                <span>{category.games_count}</span>
            </div>
            {(opened || animating) && (
                <LiveSidebarTournamentsContainer
                    tournaments={getTournaments(category.id)}
                    sportId={sportId}
                    ref={containerRef}
                />
            )}
        </li>
    );
};

export default memo(LiveSidebarCategory);
