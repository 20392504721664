import { FC, memo } from "react";
import classnames from "classnames";
import { TranslationScopes } from "@finbackoffice/enums";
import { useRuntimeConfig, IBetSlipValidationError } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import Img from "components/base/img/Img";
import styles from "./betslip-validation-error.module.sass";

const BetSlipValidationError: FC<IBetSlipValidationError> = ({ errorType, errorKey, replace }) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    return (
        <div className={classnames(styles.errorArea, styles[errorType])}>
            {errorType ? (
                <Img
                    source={`${ASSETS_URL}/common/desktop/base-icons/error-message-icon.svg`}
                    alt="Error"
                    width={18}
                    height={17}
                />
            ) : (
                <Img
                    source={`${ASSETS_URL}/common/desktop/base-icons/remove-bet-icon.svg`}
                    alt="Bet Removed"
                    width={17}
                    height={17}
                />
            )}

            <span className={styles.errorText}>
                <Translate tid={errorKey} replace={replace} namespace={TranslationScopes.BetSlip} />
            </span>
        </div>
    );
};

export default memo(BetSlipValidationError);
