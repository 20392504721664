import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import AnimateHeight, { Height } from "react-animate-height";

type Props = {
    wrapperClassName?: string;
    duration: number;
};

const AutoHeight: FC<PropsWithChildren & Props> = ({
    children,
    wrapperClassName,
    duration,
    ...props
}) => {
    const [height, setHeight] = useState<Height>("auto");
    const contentDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    return (
        <AnimateHeight
            {...props}
            height={height}
            contentClassName={wrapperClassName}
            contentRef={contentDiv}
            disableDisplayNone
            duration={duration}>
            {children}
        </AnimateHeight>
    );
};

export default AutoHeight;
