import React, { FC, SyntheticEvent, useCallback, useContext } from "react";
import classnames from "classnames";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { Feed } from "@finbackoffice/site-core";
import { Svg } from "components/base/svg/Svg";
import { PrematchContext } from "contexts";
import styles from "./simple-sidebar.module.sass";

type Props = {
    sports: Feed;
    setSelectedSport: (val: ISportFeedResponse) => void;
    selectedSportId: number | null;
    setArrowPos: (val: number) => void;
};

const SportsList: FC<Props> = ({ sports, setSelectedSport, selectedSportId, setArrowPos }) => {
    const { tournaments } = useContext(PrematchContext);

    const isSelected = useCallback(
        (sportId: number) => !!tournaments.find((item) => sportId === item.sportId),
        [tournaments],
    );

    const handleClick = useCallback(
        (e: SyntheticEvent, sport: ISportFeedResponse) => {
            setSelectedSport(sport);
            setArrowPos(e.currentTarget.getBoundingClientRect().top);
        },
        [setArrowPos, setSelectedSport],
    );

    return (
        <div className={styles.sports}>
            <ul className={styles.sportsList}>
                {sports.map((sport: ISportFeedResponse) => (
                    <li
                        key={sport.id}
                        onClick={(e) => handleClick(e, sport)}
                        className={classnames(
                            selectedSportId === sport.id && styles.opened,
                            isSelected(sport.id) && styles.selected,
                        )}>
                        <div>
                            <Svg
                                src={`/common/sport-icons/${sport.id}.svg`}
                                wrapper="span"
                                className="svg-sport-icon"
                                loader
                            />
                            <span className={styles.count}>{sport.games_count}</span>
                        </div>
                        <span className={styles.sportName} title={sport.name || ""}>
                            {sport.name}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SportsList;
