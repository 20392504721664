import { FC } from "react";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { Feed } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { useSelectedPrematchSportSlug } from "hooks";
import PrematchSidebarSport from "./PrematchSidebarSport";

type IProps = {
    sports: Feed;
};

const PrematchSidebarSportsContainer: FC<IProps> = ({ sports }) => {
    const sportSlug = useSelectedPrematchSportSlug();

    if (sports?.length === 0)
        return (
            <div className="no-game">
                <Translate tid="no_games" />
            </div>
        );

    return (
        <FadeInAnimation>
            <ul>
                {sports.map((sport: ISportFeedResponse) => (
                    <PrematchSidebarSport
                        key={sport?.id}
                        sport={sport}
                        openedByDefault={sportSlug === sport.slug}
                    />
                ))}
            </ul>
        </FadeInAnimation>
    );
};

export default PrematchSidebarSportsContainer;
