import { Dispatch, FC, SetStateAction } from "react";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import LiveSideBar from "./live/LiveSideBar";
import PrematchSidebar from "./prematch/PrematchSideBar";
import styles from "./sidebar.module.sass";
import SidebarTabs from "./tabs/SidebarTabs";
import { ISidebarViewType } from "./SidebarContainer";

type IProps = {
    setViewType: Dispatch<SetStateAction<ISidebarViewType>>;
    viewType: ISidebarViewType;
};

const SideBar: FC<IProps> = ({ setViewType, viewType }) => {
    return (
        <div className={styles.animateSideBar} data-testid="sportsbook-sidebar">
            <section className={styles.sideBar}>
                <SidebarTabs viewType={viewType} setViewType={setViewType} />
                <ScrollComponent
                    containerClass={styles.rightSide}
                    contentClass={styles.scrollableContent}
                    showScrollBar>
                    {viewType === "sport" && (
                        <ErrorBoundary name={PrematchSidebar.name}>
                            <PrematchSidebar />
                        </ErrorBoundary>
                    )}
                    {viewType === "live" && (
                        <ErrorBoundary name={LiveSideBar.name}>
                            <LiveSideBar />
                        </ErrorBoundary>
                    )}
                </ScrollComponent>
            </section>
        </div>
    );
};

export default SideBar;
