import { FC, memo } from "react";
import classnames from "classnames";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { useCollapseMargin, useRuntimeConfig } from "@finbackoffice/site-core";
import { useSocketStorage, FeedTypeEnum } from "hooks";
import Img from "components/base/img/Img";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import styles from "./live-sidebar-sport.module.sass";
import LiveSidebarCategoriesContainer from "./categories/LiveSidebarCategoriesContainer";

interface IProps {
    sport: ISportFeedResponse;
    openByDefault: boolean;
}

const LiveSidebarSport: FC<IProps> = ({ sport, openByDefault }) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { getCategories } = useSocketStorage(FeedTypeEnum.live);
    const { opened, animating, headerRef, containerRef } = useCollapseMargin(openByDefault);

    return (
        <div data-testid="sidebar-live-sport-item-container">
            <div
                className={classnames("sport-item-header", styles.sportItem, {
                    [styles.opened]: opened,
                })}
                data-testid="sidebar-live-sport-item"
                data-isopened={opened}
                ref={headerRef}>
                <Img
                    source={`${ASSETS_URL}/common/desktop/sport-headers/${sport.id}.webp`}
                    width={136}
                    height={30}
                    alt={`Sport_${sport.id}`}
                    wrapperClassName={styles.headerBg}
                />
                <Img
                    source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                    alt="arrow"
                    width={10}
                    height={5}
                    wrapperClassName={styles.arrow}
                />
                <span>{sport.name}</span>
                <span>({sport.games_count})</span>
            </div>
            {(opened || animating) && (
                <ErrorBoundary name={LiveSidebarCategoriesContainer.name}>
                    <LiveSidebarCategoriesContainer
                        categories={getCategories(sport.id)}
                        sportId={sport.id}
                        ref={containerRef}
                    />
                </ErrorBoundary>
            )}
        </div>
    );
};

export default memo(LiveSidebarSport);
