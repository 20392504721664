import { FC, PropsWithChildren, ReactNode, useContext } from "react";
import { useRouter } from "next/router";
import {
    BrowserStorageContext,
    FavoriteEventsProvider,
    FeedContext,
    FeedProvider,
} from "@finbackoffice/site-core";
import MiddleAreaHeader from "components/pages/sport/header/MiddleAreaHeader";
import BetSlip from "components/pages/sport/betslip/BetSlip";
import { useSocketStorage, FeedTypeEnum } from "hooks";
import { SIRWidgetProvider } from "contexts";
import SideBarContainer from "components/pages/sport/sidebar/SidebarContainer";
import { PrematchProvider, MultiViewProvider, SportsbookProvider } from "contexts";
import styles from "./sportsbook-layout.module.sass";

const SportsbookLayout: FC<PropsWithChildren> = ({ children }) => {
    const socketStorage = useSocketStorage(FeedTypeEnum.live);
    const { hasLiveFeedGames } = useContext(FeedContext);
    const browserStorage = useContext(BrowserStorageContext);
    const router = useRouter();

    const isHomeRoute = router.pathname === "/";

    return (
        <SIRWidgetProvider>
            <div className={styles.content}>
                <PrematchProvider>
                    <FavoriteEventsProvider
                        browserStorage={browserStorage}
                        socketStorage={socketStorage}
                        liveFeedReady={hasLiveFeedGames}>
                        <MultiViewProvider>
                            <SportsbookProvider>
                                <SideBarContainer />
                                <section
                                    className={styles.middleArea}
                                    data-testid="sportsbook-middlearea">
                                    {!isHomeRoute && <MiddleAreaHeader />}
                                    {children}
                                </section>
                                <BetSlip />
                            </SportsbookProvider>
                        </MultiViewProvider>
                    </FavoriteEventsProvider>
                </PrematchProvider>
            </div>
        </SIRWidgetProvider>
    );
};

export const getSportsbookLayout = (page: ReactNode) => (
    <FeedProvider>
        <SportsbookLayout>{page}</SportsbookLayout>
    </FeedProvider>
);
