import { Children, FC, PropsWithChildren, ReactNode, useContext, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import classnames from "classnames";
import { createPortal } from "react-dom";
import { DraggableContext } from "contexts";
import Translate from "../translate/Translate";
import styles from "./draggable.module.sass";

type IProps = {
    id: string;
    title?: string;
    renderTitle?: ReactNode;
    onClose?: () => void;
    width: number;
    position: { top: number; left: number };
    wrapperClassName?: string;
};

const Draggable2: FC<PropsWithChildren<IProps>> = ({
    children,
    id,
    title,
    renderTitle,
    onClose,
    wrapperClassName,
    position,
    width,
}) => {
    const { removeItem, setIsDragging, moveItem } = useContext(DraggableContext);
    const child = Children.only(children);
    const [activeDrags, setActiveDrags] = useState(0);

    const handleClose = () => {
        removeItem(id);
        onClose?.();
    };

    const onStart = () => {
        setIsDragging(true);
        setActiveDrags(activeDrags + 1);
    };
    const onStop = () => {
        setIsDragging(true);
        setActiveDrags(activeDrags - 1);
    };
    const onDrag = (_e: DraggableEvent, position: DraggableData) => {
        const { x, y } = position;
        moveItem(id, y, x);
    };

    const dragHandlers = { onStart, onStop, onDrag };
    return createPortal(
        <Draggable
            handle=".handle"
            position={{ x: position.left, y: position.top }}
            {...dragHandlers}
            bounds="body">
            <div
                id={`id_${id}`}
                className={classnames(styles.draggable, wrapperClassName)}
                style={{ top: 0, width }}>
                <div className={classnames(styles.header, "handle")}>
                    {title && <Translate tid={title} />}
                    {Boolean(renderTitle) && renderTitle}

                    <i className={styles.close} onClick={handleClose} />
                </div>
                {child}
            </div>
        </Draggable>,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        document.getElementById("body")!,
    );
};

export default Draggable2;
