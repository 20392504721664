import { IBetSlipError } from "@finbackoffice/fe-core";
import { FC, memo, useContext, useMemo } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { ConfigContext, BetSlipErrorKeys, ERROR_CODES } from "@finbackoffice/site-core";
import { formatAmount } from "components/base/currency-formater/CurrencyFormater";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Translate from "components/base/translate/Translate";
import styles from "./betslip-error.module.sass";

type Props = {
    error: Partial<IBetSlipError> | null;
};

const BetSlipError: FC<Props> = ({ error }) => {
    const { siteCurrencyFormat } = useContext(ConfigContext);
    const { isPositive, details, code, message } = error ?? {};
    const recommended = parseFloat(details?.recommended || "");

    const errorMessage = useMemo(() => {
        switch (error?.error) {
            case BetSlipErrorKeys.bet_max_min_bet:
                return (
                    <Translate tid="betSlip_bet_max_error" namespace={TranslationScopes.BetSlip} />
                );
            case BetSlipErrorKeys.min_bet_error:
                if (isPositive) {
                    return (
                        <Translate
                            tid="betSlip_min_bet_error_positive"
                            replace={{
                                amount: `${
                                    formatAmount({
                                        format: siteCurrencyFormat?.[details?.currency] ?? null,
                                        amount: recommended.toString(),
                                        withCode: false,
                                        withSymbol: true,
                                    }) ?? "-"
                                }`,
                            }}
                            namespace={TranslationScopes.BetSlip}
                        />
                    );
                }

                return (
                    <Translate
                        tid="betSlip_min_bet_error_negative"
                        namespace={TranslationScopes.BetSlip}
                    />
                );
            case BetSlipErrorKeys.max_bet_error:
                if (!recommended) return null;

                if (isPositive) {
                    return (
                        <Translate
                            tid="betSlip_max_bet_error_positive"
                            replace={{
                                amount: `${
                                    formatAmount({
                                        format: siteCurrencyFormat?.[details?.currency] ?? null,
                                        amount: recommended.toString(),
                                        withCode: false,
                                        withSymbol: true,
                                    }) ?? "-"
                                }`,
                            }}
                            namespace={TranslationScopes.BetSlip}
                        />
                    );
                }
                return (
                    <Translate
                        tid="betSlip_max_bet_error_negative"
                        namespace={TranslationScopes.BetSlip}
                    />
                );
            case BetSlipErrorKeys.max_win_error:
                if (!recommended) return null;

                if (isPositive)
                    return (
                        <Translate
                            tid="betSlip_max_win_error_positive"
                            replace={{
                                amount: `${
                                    formatAmount({
                                        format: siteCurrencyFormat?.[details?.currency] ?? null,
                                        amount: recommended.toString(),
                                        withCode: false,
                                        withSymbol: true,
                                    }) ?? "-"
                                }`,
                            }}
                            namespace={TranslationScopes.BetSlip}
                        />
                    );
                return (
                    <Translate
                        tid="betSlip_max_win_error_negative"
                        namespace={TranslationScopes.BetSlip}
                    />
                );
            case BetSlipErrorKeys.select_another_price:
                return (
                    <Translate
                        tid="betSlip_selectAnotherPriceError"
                        namespace={TranslationScopes.BetSlip}
                    />
                );
            case BetSlipErrorKeys.special_requirements:
                return (
                    <Translate
                        tid="betSlip_specialRequirementsError"
                        namespace={TranslationScopes.BetSlip}
                    />
                );
            case BetSlipErrorKeys.mts_error:
                if (code) {
                    if (code >= 100 && code < 300) {
                        return (
                            <>
                                {`${code}: `}
                                <Translate
                                    tid="betSlip_mts_error_technical"
                                    namespace={TranslationScopes.BetSlip}
                                />
                            </>
                        );
                    }

                    if (code >= 300 && code < 400) {
                        return (
                            <>
                                {`${code}: `}
                                <Translate
                                    tid="betSlip_mts_error_ticket"
                                    namespace={TranslationScopes.BetSlip}
                                />
                            </>
                        );
                    }

                    if (ERROR_CODES.mts.match.includes(code)) {
                        return (
                            <>
                                {`${code}: `}
                                <Translate
                                    tid="betSlip_mts_error_match"
                                    namespace={TranslationScopes.BetSlip}
                                />
                            </>
                        );
                    }

                    if (ERROR_CODES.mts.notActive.includes(code)) {
                        return (
                            <>
                                {`${code}: `}
                                <Translate
                                    tid="betSlip_mts_error_notActive"
                                    namespace={TranslationScopes.BetSlip}
                                />
                            </>
                        );
                    }

                    if (code >= 500 && code < 800) {
                        return (
                            <>
                                {`${code}: `}
                                <Translate
                                    tid="betSlip_mts_error_limit"
                                    namespace={TranslationScopes.BetSlip}
                                />
                            </>
                        );
                    }
                }

                return (
                    <>
                        {`${code}: `}
                        <Translate
                            tid="betSlip_mts_error_unknown"
                            namespace={TranslationScopes.BetSlip}
                        />
                    </>
                );
            default:
                return (
                    <p>
                        {`Error: ${error?.error}`}
                        <br />
                        {message
                            ? `Message: ${typeof message === "string" ? message : message[0]}`
                            : ""}
                        <br />
                        {`Code: ${code}`}
                        <br />
                        {details?.message && `Mts Message: ${details?.message}`}
                    </p>
                );
        }
    }, [
        error?.error,
        isPositive,
        recommended,
        siteCurrencyFormat,
        details?.currency,
        details?.message,
        code,
        message,
    ]);

    return (
        Boolean(errorMessage) &&
        !!error && (
            <FadeInAnimation>
                <div className={styles.oddStatus}>
                    <span className={styles.suspended}>{errorMessage}</span>
                </div>
            </FadeInAnimation>
        )
    );
};

export default memo(BetSlipError);
