import { FC, PropsWithChildren, useEffect, useState } from "react";

const ClientOnlyComponent: FC<PropsWithChildren> = ({ children }) => {
    const [isCSR, setIsCSR] = useState(false);

    useEffect(() => {
        setIsCSR(true);
    }, []);

    return isCSR ? children : null;
};

export default ClientOnlyComponent;
