import { FC, memo, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import classnames from "classnames";
import {
    Currency,
    MarketStatus,
    TicketType,
    TranslationScopes,
    isCryptoCurrency,
} from "@finbackoffice/enums";
import { getStakeValue, IBetItemData, OddFormatter } from "@finbackoffice/fe-core";
import {
    AuthContext,
    BetSlipContext,
    ConfigContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
    useBetValidation,
} from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import { MarketUpdatesContext } from "contexts";
import { useLimits } from "hooks";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Loading from "components/base/loading/Loading";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import { Svg } from "components/base/svg/Svg";
import BetSlipError from "../error/BetSlipError";
import StakeItem from "../stake-item/StakeItem";
import styles from "./betslip-item.module.sass";

type IBetSlipItemProps = {
    bet: IBetItemData;
};

const BetSlipItem: FC<IBetSlipItemProps> = ({ bet }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { getPossibleBetMax, siteMinBet, requestBetMax, isLoadingBetMax } = useLimits();
    const { unsubscribeMarket } = useContext(MarketUpdatesContext);
    const { currentCurrency, userCurrency } = useContext(UserAccountContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const {
        exchangeRates,
        exchangeRatesIsReady,
        usdExchangeRate,
        userCurrencyExchangeRate,
        fxCurrencyExchangeRate,
    } = useContext(ExchangeRatesContext);
    const { removeBetItem, updateBetItem, betType } = useContext(BetSlipContext);
    const maxBet = parseFloat((bet.tempBetLimits?.amount || bet.limits?.amount) ?? "");
    const {
        siteConfigReady,
        siteCurrencyFormat,
        isBetProcessorEnabled,
        siteCurrentOddFormat,
        betProcessorConfig,
    } = useContext(ConfigContext);
    const { isOutcomeChanged, needToRemove, outcomeRemoved, validateStake } = useBetValidation();
    const outcomeChanged = isOutcomeChanged(bet);
    const statusChanged = bet.marketStatus && needToRemove(bet.marketStatus, bet.outcomeStatus);
    const removed =
        (bet.marketStatus &&
            [MarketStatus.WaitingForResult, MarketStatus.Settled].indexOf(bet.marketStatus) !==
                -1) ||
        outcomeRemoved(bet.outcomeStatus);

    const blocked = bet.marketStatus === MarketStatus.OnHold;
    const acceptedTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const fxCurrency = COMMON_SITE_CONFIGS.wallet.fxCurrency;
    const exchangeRate = fiatEnabled ? userCurrencyExchangeRate : fxCurrencyExchangeRate;

    const handleStakeChange = useCallback(
        (value: string, isFromServer?: boolean) => {
            if (currentCurrency) {
                const decimalDigits = siteCurrencyFormat?.[currentCurrency].decimal_digits ?? 0;

                const stake = value
                    ? getStakeValue(value, {
                          maxBet: isFromServer ? parseFloat(value) : maxBet,
                          decimal_digits: decimalDigits,
                          isBetProcessorEnabled,
                      })
                    : "";

                const error = validateStake(stake, bet.error, bet.tempBetLimits);

                const updatedBetItem: Partial<IBetItemData> = {
                    outcomeId: bet.outcomeId,
                    stake,
                };

                if (error) {
                    updatedBetItem.error = error;
                }

                updateBetItem(updatedBetItem);
            }
        },
        [
            currentCurrency,
            siteCurrencyFormat,
            maxBet,
            isBetProcessorEnabled,
            bet.error,
            bet.tempBetLimits,
            bet.outcomeId,
            updateBetItem,
            validateStake,
        ],
    );

    const handleBetMax = useCallback(async () => {
        const bets = [
            {
                event_id: bet.gameId,
                market_id: bet.marketId,
                outcome_id: bet.outcomeId,
                odds: bet.outcomeValue,
            },
        ];
        const response = await requestBetMax(bets);

        if (response) {
            if (parseFloat(response.amount) < parseFloat(response.min_bet)) {
                updateBetItem({
                    outcomeId: bet.outcomeId,
                    error: {
                        error: "bet_max_min_bet",
                        visible: true,
                    },
                });
            } else {
                handleStakeChange(response.amount);
            }
        }
    }, [
        bet.gameId,
        bet.marketId,
        bet.outcomeId,
        bet.outcomeValue,
        requestBetMax,
        handleStakeChange,
        updateBetItem,
    ]);

    const remove = useCallback(() => {
        removeBetItem(bet.outcomeId);
        unsubscribeMarket(bet.outcomeId);
    }, [bet.outcomeId, unsubscribeMarket, removeBetItem]);

    useEffect(() => {
        if (bet.accepted) {
            remove();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betType]);

    useEffect(() => {
        handleStakeChange(bet.stake);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCurrency]);

    useEffect(() => {
        if (!isUserLoggedIn) {
            updateBetItem({
                outcomeId: bet.outcomeId,
                limits: {
                    min_bet: siteMinBet.toString(),
                    amount: getPossibleBetMax(parseFloat(bet.outcomeValue)).toString(),
                    currency: currentCurrency ?? Currency.KRW,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeRates]);

    useEffect(() => {
        if (acceptedTimeoutRef.current) {
            clearTimeout(acceptedTimeoutRef.current);
        }

        if (bet.accepted) {
            acceptedTimeoutRef.current = setTimeout(() => {
                remove();
            }, 5000);
        }

        return () => {
            if (acceptedTimeoutRef.current) {
                clearTimeout(acceptedTimeoutRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bet.accepted]);

    const renderStatus = useMemo(() => {
        if (statusChanged) {
            if (outcomeRemoved(bet.outcomeStatus)) {
                return (
                    <Translate
                        tid="betSlip_waitingforresult"
                        namespace={TranslationScopes.BetSlip}
                    />
                );
            }

            return (
                <Translate
                    tid={`betSlip_${bet.marketStatus?.toLowerCase()}`}
                    namespace={TranslationScopes.BetSlip}
                />
            );
        }
        if (outcomeChanged) {
            return (
                <Translate
                    tid="betSlip_oddsChanged"
                    replace={{
                        oldValue: bet.outcomeInitValue,
                        newValue: bet.outcomeValue,
                    }}
                    namespace={TranslationScopes.BetSlip}
                />
            );
        }
        return null;
    }, [
        bet.marketStatus,
        bet.outcomeInitValue,
        bet.outcomeStatus,
        bet.outcomeValue,
        outcomeChanged,
        outcomeRemoved,
        statusChanged,
    ]);

    const renderAmount = useCallback(
        (amount: number) => (
            <>
                {currentCurrency && (
                    <strong>
                        <CurrencyFormatter
                            currency={currentCurrency}
                            amount={amount.toString()}
                            withCode={false}
                        />{" "}
                        <Svg
                            src={`/common/currencies/${currentCurrency?.toLowerCase()}.svg`}
                            wrapper="span"
                            className="svg-icon"
                        />
                    </strong>
                )}
                {currentCurrency && isCryptoCurrency(currentCurrency) && exchangeRatesIsReady && (
                    <span>
                        /{" "}
                        {isUserLoggedIn ? (
                            <CurrencyFormatter
                                currency={fiatEnabled ? userCurrency : fxCurrency}
                                amount={(amount * exchangeRate).toString()}
                                withCode={false}
                                withSymbol
                            />
                        ) : (
                            <CurrencyFormatter
                                currency={Currency.USD}
                                amount={(amount * usdExchangeRate).toString()}
                                withCode={false}
                                withSymbol
                            />
                        )}
                    </span>
                )}
            </>
        ),
        [
            currentCurrency,
            exchangeRate,
            exchangeRatesIsReady,
            fiatEnabled,
            fxCurrency,
            isUserLoggedIn,
            usdExchangeRate,
            userCurrency,
        ],
    );

    const toWinAmount = useMemo(
        () => (bet.stake ? parseFloat(bet.stake) : 0) * parseFloat(bet.outcomeValue),
        [bet.outcomeValue, bet.stake],
    );

    return (
        <div
            className={classnames(
                styles.betSlipItem,
                (bet.accepted || bet.loading || removed) && styles.removedBetItem,
            )}>
            <div className={styles.gameNameRow}>
                <span className={styles.closeItem} onClick={() => remove()} />
                <span className={styles.gameName} title={`${bet.team1Name} vs ${bet.team2Name}`}>
                    {bet.team1Name} vs {bet.team2Name}
                </span>
            </div>
            <div className={styles.marketNameContainer}>
                <div>
                    <span className={styles.marketName}>{bet.marketName}</span>
                    <span className={styles.outcomeName}>
                        <Translate tid="betSlip_pick" namespace={TranslationScopes.BetSlip} />
                        <span>{bet.outcomeName}</span>
                    </span>
                </div>
                <span
                    className={classnames(
                        styles.outcomeValue,
                        !bet.accepted &&
                            !bet.loading &&
                            !statusChanged &&
                            outcomeChanged &&
                            styles.outcomeChanged,
                    )}>
                    {OddFormatter.format(
                        parseFloat(
                            bet.accepted || bet.loading
                                ? bet.outcomeInitValue
                                : (bet.outcomeValue as string),
                        ),
                        siteCurrentOddFormat,
                    )}
                </span>
            </div>
            {betType === TicketType.Single && (
                <>
                    <div className={styles.stakeRow}>
                        <span>
                            <Translate tid="betSlip_stake" namespace={TranslationScopes.BetSlip} />
                        </span>
                        <StakeItem
                            value={bet.stake}
                            onStakeChange={handleStakeChange}
                            onBetMax={handleBetMax}
                            disabled={
                                (bet.error &&
                                    bet.error.visible &&
                                    bet.error.isPositive === false) ||
                                removed ||
                                blocked
                            }
                            isBetMaxDisabled={isLoadingBetMax}
                            limits={(bet.tempBetLimits || bet.limits) ?? null}
                        />
                    </div>
                    {!betProcessorConfig?.tax.enabled && (
                        <div className={styles.toWinRow}>
                            {siteConfigReady ? (
                                <>
                                    <Translate
                                        tid="betSlip_toWin"
                                        namespace={TranslationScopes.BetSlip}
                                    />
                                    :{renderAmount(toWinAmount)}
                                </>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    )}
                </>
            )}
            {!bet.accepted && !bet.loading && (statusChanged || outcomeChanged) && (
                <div className={styles.oddStatus}>
                    <span>{renderStatus}</span>
                </div>
            )}
            {bet.error && bet.error.visible && <BetSlipError error={bet.error} />}
            {betType === TicketType.Single && bet.accepted && (
                <FadeInAnimation>
                    <span className={styles.acceptedBet} data-testid="bets-accepted-container">
                        <Translate
                            tid="betSlip_yourBetsAccepted"
                            namespace={TranslationScopes.BetSlip}
                        />
                    </span>
                </FadeInAnimation>
            )}
            {bet.loading && (
                <div
                    className={classnames(
                        styles.spinner,
                        betType === TicketType.Single && styles.spinnerForSingleBet,
                    )}>
                    <Svg src="/common/desktop/base-icons/spinner.svg" />
                </div>
            )}
        </div>
    );
};

export default memo(BetSlipItem);
