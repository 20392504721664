import { FC, memo, useCallback, useState, SyntheticEvent, useContext } from "react";
import classnames from "classnames";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { FavoriteEventsContext, FeedContext } from "@finbackoffice/site-core";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import Translate from "components/base/translate/Translate";
import Loading from "components/base/loading/Loading";
import PrematchTimeFilterTabs from "components/pages/sport/sidebar/base/prematch-time-filter-tabs/PrematchTimeFilterTabs";
import ActiveLink from "components/base/active-link/ActiveLink";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import { PrematchContext, InitialDataContext } from "contexts";
import SportsList from "../SportsList";
import styles from "../simple-sidebar.module.sass";
import SimpleSidebarPopover from "../SimpleSidebarPopover/SimpleSidebarPopover";
import PrematchSidebarSport from "../../prematch/sport/PrematchSidebarSport";
import PrematchTopLeagues from "../../prematch/top-leagues/PrematchTopLeagues";

const timefilterHeight = 32;

const PrematchSimpleSidebar: FC = () => {
    const { favPrematchLeaguesGameCount } = useContext(FavoriteEventsContext);
    const { siteHeaderHeight } = useContext(InitialDataContext);
    const { timeFilter } = useContext(PrematchContext);
    const { prematchFeed, prematchIsLoading } = useContext(FeedContext);
    const [sport, setSport] = useState<ISportFeedResponse | null>(null);
    const [arrowPosition, setArrowPosition] = useState<number>(0);
    const [isTimeFilterOpen, setTimeFilterOpen] = useState<boolean>(false);
    const [isTopLeaguesOpen, setIsTopLeaguesOpen] = useState<boolean>(false);

    const sportId = sport?.id;
    const timefilterTop = siteHeaderHeight + timefilterHeight / 2;

    const handleClose = useCallback(() => {
        setIsTopLeaguesOpen(false);
        setTimeFilterOpen(false);
        setSport(null);
    }, []);

    const handleScroll = useCallback(() => {
        handleClose();
        setTimeFilterOpen(false);
    }, [handleClose]);

    const handleSetSelectedSport = useCallback(
        (val: ISportFeedResponse) => {
            if (val.id === sportId) {
                handleClose();
            } else {
                setSport(val);
            }

            setTimeFilterOpen(false);
            setIsTopLeaguesOpen(false);
        },
        [handleClose, sportId],
    );

    const handleSelectTopLeague = useCallback((event: SyntheticEvent) => {
        setIsTopLeaguesOpen((state) => !state);
        setTimeFilterOpen(false);
        setSport(null);
        setArrowPosition(event.currentTarget.getBoundingClientRect().top);
    }, []);

    const handleSelectTimeFilter = useCallback((event: SyntheticEvent) => {
        setIsTopLeaguesOpen(false);
        setTimeFilterOpen((state) => !state);
        setSport(null);
        setArrowPosition(event.currentTarget.getBoundingClientRect().top);
    }, []);

    const isSportSelected = Boolean(sport);
    const isTopLeaguesShow = isTopLeaguesOpen && !isSportSelected && !isTimeFilterOpen;
    const isTimeFilterShow = isTimeFilterOpen && !isSportSelected && !isTopLeaguesOpen;
    const isSportsShow = isSportSelected && !isTopLeaguesOpen && !isTimeFilterOpen;
    const arrowShift = isTimeFilterOpen ? timefilterTop : siteHeaderHeight;

    const topLeaguesNav = (
        <div onClick={handleSelectTopLeague}>
            <div className={classnames(styles.topLeagues, { [styles.open]: isTopLeaguesOpen })}>
                <Svg
                    src="/common/desktop/base-icons/top-leagues.svg"
                    wrapper="span"
                    className="svg-icon"
                />
                <Translate tid="sidebar_prematch_topLeagues" />
            </div>
        </div>
    );

    const timeFilterNav = (
        <div
            onClick={handleSelectTimeFilter}
            className={classnames(styles.timeFilter, isTimeFilterOpen && styles.opened)}>
            <span>{timeFilter ? `${timeFilter}H` : "All"}</span>
        </div>
    );

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <span className={styles.header}>
                <Translate tid="pageName_sport" />
            </span>
            <ScrollComponent
                containerClass={styles.rightSide}
                contentClass={styles.scrollableContent}
                showScrollBar
                onScroll={handleScroll}>
                {!!favPrematchLeaguesGameCount && (
                    <ActiveLink
                        href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.FAVORITES}`}
                        includes={`/${RouteNames.FAVORITES}`}
                        activeClassName={styles.open}
                        className={styles.topLeagues}>
                        <>
                            <span className={styles.count}>{favPrematchLeaguesGameCount}</span>
                            <i className={styles.favoriteStar} />
                            Favorite Events
                        </>
                    </ActiveLink>
                )}
                {topLeaguesNav}
                {timeFilterNav}
                {!prematchIsLoading ? (
                    <SportsList
                        sports={prematchFeed}
                        setSelectedSport={handleSetSelectedSport}
                        selectedSportId={sport?.id || null}
                        setArrowPos={setArrowPosition}
                    />
                ) : (
                    <Loading />
                )}
            </ScrollComponent>

            {(isSportSelected || isTopLeaguesOpen || isTimeFilterOpen) && (
                <SimpleSidebarPopover
                    arrowPosition={arrowPosition}
                    arrowShift={arrowShift}
                    onClose={handleClose}>
                    {isTopLeaguesShow && <PrematchTopLeagues onSelect={handleClose} />}
                    {isTimeFilterShow && <PrematchTimeFilterTabs onSelect={handleClose} />}
                    {sport && isSportsShow && (
                        <PrematchSidebarSport sport={sport} openedByDefault />
                    )}
                </SimpleSidebarPopover>
            )}
        </div>
    );
};

export default memo(PrematchSimpleSidebar);
