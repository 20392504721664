import { TicketType } from "@finbackoffice/enums";
import { useRuntimeConfig, BetSlipContext, ConfigContext } from "@finbackoffice/site-core";
import classnames from "classnames";
import { FC, useContext, useCallback } from "react";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";

const BetSlipTabs: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { setBetType, betType, betItems } = useContext(BetSlipContext);
    const { isSportMultiBetBonusesEnabled } = useContext(ConfigContext);

    const onBetTypeChange = useCallback(
        (val: TicketType) => {
            setBetType(val);
        },
        [setBetType],
    );

    return (
        <nav className="main-tabs">
            <Button
                type="button"
                disabled={isSportMultiBetBonusesEnabled && betItems.length > 1}
                className={classnames("main-tabs-tab", betType === TicketType.Single && "active")}
                onClick={() => onBetTypeChange(TicketType.Single)}>
                <Translate tid={`betType_${TicketType.Single.toLowerCase()}`} />
            </Button>
            <Button
                type="button"
                className={classnames("main-tabs-tab", betType === TicketType.Express && "active")}
                onClick={() => onBetTypeChange(TicketType.Express)}>
                <Translate tid={`betType_${TicketType.Express.toLowerCase()}`} />
            </Button>
            {COMMON_SITE_CONFIGS.betslip.system.enable && (
                <Button
                    type="button"
                    className={classnames(
                        "main-tabs-tab",
                        betType === TicketType.System && "active",
                    )}
                    onClick={() => onBetTypeChange(TicketType.System)}>
                    <Translate tid={`betType_${TicketType.System.toLowerCase()}`} />
                </Button>
            )}
        </nav>
    );
};

export default BetSlipTabs;
