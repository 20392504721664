import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import classnames from "classnames";
import { getStakeValue, IBetItemData, IBetSlipError } from "@finbackoffice/fe-core";
import { IMaxTicketAmountResponse } from "@finbackoffice/clientbff-client";
import { TranslationScopes, isCryptoCurrency } from "@finbackoffice/enums";
import {
    AuthContext,
    BetSlipContext,
    ConfigContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
    BetSlipErrorKeys,
    ErrorKeys,
    IBetSlipValidationError,
    useBetValidation,
    MultiBetBonusContext,
} from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import { useLimits } from "hooks";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import styles from "../total-stake.module.sass";
import StakeItem from "../../stake-item/StakeItem";

type Props = {
    validationError: IBetSlipValidationError | null;
    disabled: boolean;
    betError: IBetSlipError | null;
    betItems: IBetItemData[];
    totalOdds: number;
};

const MultipleTotalStake: FC<Props> = ({
    validationError,
    disabled,
    betError,
    betItems,
    totalOdds,
}) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { getPossibleBetMax, siteMinBet, requestBetMax, isLoadingBetMax } = useLimits();
    const {
        siteCurrencyFormat,
        isBetProcessorEnabled,
        isSportMultiBetBonusesEnabled,
        betProcessorConfig,
    } = useContext(ConfigContext);
    const { currentCurrency, userCurrency } = useContext(UserAccountContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { userCurrencyExchangeRate: exchangeRate } = useContext(ExchangeRatesContext);
    const { currentRange, validation, possibleBonusAmount } =
        useContext(MultiBetBonusContext) ?? {};
    const {
        updateBetItem,
        changeMultipleBetStake,
        setBetError,
        multipleBetStake,
        betProcessing,
        possibleWinAmount,
        getTaxAmount,
        getTotalWinAmount,
    } = useContext(BetSlipContext);
    const { validateStake } = useBetValidation();

    const limits: IMaxTicketAmountResponse | null = useMemo(() => {
        if (currentCurrency) {
            const needRecommended = [
                BetSlipErrorKeys.max_bet_error,
                BetSlipErrorKeys.max_win_error,
            ].includes((betError?.error as keyof typeof BetSlipErrorKeys) ?? "");

            const minBet =
                !!betError && betError.details?.min_bet
                    ? betError.details?.min_bet
                    : siteMinBet.toString();

            const maxBet =
                !!betError && needRecommended && !!betError.details?.recommended
                    ? betError.details.recommended
                    : getPossibleBetMax(totalOdds).toString();

            return {
                amount: maxBet,
                min_bet: minBet,
                currency: currentCurrency,
            };
        }
        return null;
    }, [betError, getPossibleBetMax, currentCurrency, siteMinBet, totalOdds]);

    const onStakeChange = useCallback(
        (value: string): void => {
            if (currentCurrency) {
                const decimalDigits = siteCurrencyFormat?.[currentCurrency].decimal_digits ?? 0;

                const stake = value
                    ? getStakeValue(value, {
                          maxBet: limits ? parseFloat(limits?.amount) : 0,
                          decimal_digits: decimalDigits,
                          isBetProcessorEnabled,
                      })
                    : "";

                changeMultipleBetStake(stake);

                const error = limits ? validateStake(stake, betError, limits) : null;

                if (error) {
                    setBetError(error);
                }
            }
        },
        [
            currentCurrency,
            siteCurrencyFormat,
            limits,
            isBetProcessorEnabled,
            changeMultipleBetStake,
            validateStake,
            betError,
            setBetError,
        ],
    );

    const handleBetMax = useCallback(async () => {
        const bets = betItems.map((bet) => ({
            event_id: bet.gameId,
            market_id: bet.marketId,
            outcome_id: bet.outcomeId,
            odds: bet.outcomeValue,
        }));
        const response = await requestBetMax(bets);

        if (response) {
            if (parseFloat(response.amount) < parseFloat(response.min_bet)) {
                betItems.forEach((bet) => {
                    updateBetItem({
                        outcomeId: bet.outcomeId,
                        error: {
                            error: "bet_max_min_bet",
                            visible: true,
                        },
                    });
                });
            } else {
                onStakeChange(response.amount);
            }
        }
    }, [betItems, onStakeChange, requestBetMax, updateBetItem]);

    useEffect(() => {
        onStakeChange(multipleBetStake);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCurrency]);

    const renderAmount = useCallback(
        (amount: number) =>
            currentCurrency && (
                <span>
                    <strong>
                        <CurrencyFormatter
                            currency={currentCurrency}
                            amount={amount.toString()}
                            withCode={false}
                        />{" "}
                        <Svg
                            src={`/common/currencies/${currentCurrency.toLowerCase()}.svg`}
                            wrapper="span"
                            className="svg-icon"
                        />
                    </strong>
                    {isUserLoggedIn && exchangeRate && isCryptoCurrency(currentCurrency) && (
                        <span>
                            <CurrencyFormatter
                                currency={userCurrency}
                                amount={(amount * exchangeRate).toString()}
                                withCode={false}
                                withSymbol
                            />
                        </span>
                    )}
                </span>
            ),
        [currentCurrency, exchangeRate, isUserLoggedIn, userCurrency],
    );

    return (
        <div className={styles.totalStake}>
            <div className={styles.totalStakeItem}>
                <span>
                    <Translate tid="betSlip_totalOdds" namespace={TranslationScopes.BetSlip} />
                </span>
                <span className={styles.totalOddsWarning}>
                    {totalOdds}
                    {validationError?.errorKey === ErrorKeys.MAX_ODDS && (
                        <Img
                            source={`${ASSETS_URL}/common/desktop/base-icons/error-message-icon.svg`}
                            alt="error"
                            width={18}
                            height={17}
                            wrapperClassName={styles.totalOddsWarningIcon}
                        />
                    )}
                </span>
            </div>
            <div
                className={classnames(
                    styles.totalStakeItem,
                    styles.notSingleTotalStakeItem,
                    betProcessing && styles.betProcessing,
                )}>
                <span>
                    <Translate tid="betSlip_stake" namespace={TranslationScopes.BetSlip} />
                </span>
                <span>
                    <StakeItem
                        value={multipleBetStake}
                        onStakeChange={onStakeChange}
                        limits={limits}
                        disabled={disabled}
                        onBetMax={handleBetMax}
                        isBetMaxDisabled={isLoadingBetMax}
                    />
                </span>
            </div>
            {isSportMultiBetBonusesEnabled && currentRange && validation.valid && (
                <div className={styles.totalStakeItem}>
                    <span>
                        <Translate tid="multibet_bonus" replace={{ amount: currentRange?.rate }} />
                    </span>
                    {renderAmount(possibleBonusAmount)}
                </div>
            )}
            {betProcessorConfig?.tax.enabled ? (
                <div className={styles.totalStakeItem}>
                    <span>
                        <Translate
                            tid="betSlip_tax"
                            namespace={TranslationScopes.BetSlip}
                            replace={{ amount: betProcessorConfig?.tax.rate }}
                        />
                    </span>
                    {renderAmount(getTaxAmount(possibleBonusAmount))}
                </div>
            ) : (
                <div className={styles.totalStakeItem}>
                    <span>
                        <Translate
                            tid="betSlip_possibleWin"
                            namespace={TranslationScopes.BetSlip}
                        />
                    </span>
                    {renderAmount(possibleWinAmount)}
                </div>
            )}
            {(isSportMultiBetBonusesEnabled || betProcessorConfig?.tax.enabled) && (
                <div className={styles.totalStakeItem}>
                    <span>
                        <Translate tid="betSlip_totalWin" namespace={TranslationScopes.BetSlip} />
                    </span>
                    {renderAmount(getTotalWinAmount(possibleBonusAmount))}
                </div>
            )}
        </div>
    );
};

export default MultipleTotalStake;
