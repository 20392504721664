import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import { FC, memo } from "react";
import LiveSidebarGame from "./LiveSidebarGame";

interface IProps {
    games: IEventFeedResponse[];
}

const LiveSidebarGamesContainer: FC<IProps> = ({ games }) => {
    return (
        <>
            {games.map((game) => (
                <LiveSidebarGame key={game.id} game={game} />
            ))}
        </>
    );
};

export default memo(LiveSidebarGamesContainer);
