import { FC, useContext, useCallback } from "react";
import { TranslationScopes, isCryptoCurrency } from "@finbackoffice/enums";
import {
    AuthContext,
    BetSlipContext,
    ConfigContext,
    ExchangeRatesContext,
    UserAccountContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import { Svg } from "components/base/svg/Svg";
import styles from "../total-stake.module.sass";

const SingleTotalStake: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { currentCurrency, userCurrency } = useContext(UserAccountContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { userCurrencyExchangeRate, fxCurrencyExchangeRate } = useContext(ExchangeRatesContext);
    const { totalStake, possibleWinAmount, getTaxAmount, getTotalWinAmount } =
        useContext(BetSlipContext);
    const { betProcessorConfig } = useContext(ConfigContext);
    const fiatEnabled = COMMON_SITE_CONFIGS.wallet.fiat;
    const fxCurrency = COMMON_SITE_CONFIGS.wallet.fxCurrency;
    const exchangeRate = fiatEnabled ? userCurrencyExchangeRate : fxCurrencyExchangeRate;

    const renderAmount = useCallback(
        (amount: number) =>
            currentCurrency ? (
                <span>
                    <strong>
                        <CurrencyFormatter
                            currency={currentCurrency}
                            amount={amount.toString()}
                            withCode={false}
                        />{" "}
                        <Svg
                            src={`/common/currencies/${currentCurrency?.toLowerCase()}.svg`}
                            wrapper="span"
                            className="svg-icon"
                        />
                    </strong>
                    {isUserLoggedIn && exchangeRate && isCryptoCurrency(currentCurrency) && (
                        <CurrencyFormatter
                            currency={fiatEnabled ? userCurrency : fxCurrency}
                            amount={(amount * exchangeRate).toString()}
                            withCode={false}
                            withSymbol
                        />
                    )}
                </span>
            ) : null,
        [currentCurrency, exchangeRate, fiatEnabled, fxCurrency, isUserLoggedIn, userCurrency],
    );

    return (
        <div className={styles.totalStake}>
            <div className={styles.totalStakeItem}>
                <span>
                    <Translate tid="betSlip_totalStake" namespace={TranslationScopes.BetSlip} />
                </span>
                {renderAmount(totalStake)}
            </div>
            {betProcessorConfig?.tax.enabled ? (
                <>
                    <div className={styles.totalStakeItem}>
                        <span>
                            <Translate
                                tid="betSlip_tax"
                                namespace={TranslationScopes.BetSlip}
                                replace={{ amount: betProcessorConfig?.tax.rate }}
                            />
                        </span>
                        {renderAmount(getTaxAmount())}
                    </div>
                    <div className={styles.totalStakeItem}>
                        <span>
                            <Translate
                                tid="betSlip_totalWin"
                                namespace={TranslationScopes.BetSlip}
                            />
                        </span>
                        {renderAmount(getTotalWinAmount())}
                    </div>
                </>
            ) : (
                <div className={styles.totalStakeItem}>
                    <span>
                        <Translate
                            tid="betSlip_possibleWin"
                            namespace={TranslationScopes.BetSlip}
                        />
                    </span>
                    {renderAmount(possibleWinAmount)}
                </div>
            )}
        </div>
    );
};

export default SingleTotalStake;
