import { FC, SyntheticEvent, useMemo, memo, useCallback, useContext } from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import { ITournamentFeedResponse } from "@finbackoffice/websocket-client";
import { useSelectedPrematchTournamentId } from "hooks";
import ActiveLink from "components/base/active-link/ActiveLink";
import { PrematchContext } from "contexts";
import { RouteNames } from "utils/constants";
import styles from "./prematch-sidebar-tournament.module.sass";

interface IProps {
    tournament: ITournamentFeedResponse;
    sportId: number;
    sportSlug: string;
    categoryId: number;
}

const PrematchSidebarTournament: FC<IProps> = ({ tournament, sportId, categoryId, sportSlug }) => {
    const { addTournament, removeTournament, changeTournament, tournaments } =
        useContext(PrematchContext);
    const router = useRouter();
    const selectedTournamentId = useSelectedPrematchTournamentId();

    const checked: boolean = useMemo(
        () => !!tournaments.find((item) => tournament.id === item.tournamentId),
        [tournament, tournaments],
    );

    const onCheckBoxClickHandler = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation();
            event.preventDefault();

            if (checked) {
                removeTournament(tournament?.id);
            } else {
                addTournament({
                    sportId,
                    categoryId,
                    tournamentId: tournament?.id,
                });
            }
        },
        [addTournament, categoryId, checked, removeTournament, sportId, tournament?.id],
    );

    const onItemClickHandler = useCallback(() => {
        if (tournament.id !== selectedTournamentId) {
            changeTournament({
                sportId,
                categoryId,
                tournamentId: tournament?.id,
            });
        }
    }, [tournament.id, selectedTournamentId, sportId, categoryId, changeTournament]);

    return (
        <ActiveLink
            href={{
                pathname: `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/[[...prematchGameRoutes]]`,
                query: {
                    ...router.query,
                    prematchGameRoutes: [
                        sportSlug,
                        categoryId.toString(),
                        tournament.id.toString(),
                    ],
                },
            }}
            as={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${sportSlug}/${categoryId}/${tournament.id}`}
            activeClassName={styles.checked}
            className={styles.tournamentItem}
            onClick={onItemClickHandler}
            data-testid="sidebar-prematch-tournament-item-container">
            <>
                <label
                    htmlFor="checkbox"
                    className={classnames("ui-checkbox", styles.checkbox, checked && "checked")}>
                    <input type="checkbox" id="checkbox" />
                    <i onClick={onCheckBoxClickHandler} />
                </label>
                <span>{tournament.name}</span>
            </>
        </ActiveLink>
    );
};

export default memo(PrematchSidebarTournament);
